<template>
  <b-container fluid>
    <b-row>
      <b-col v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>{{message}}</b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <h1>{{ source.source }}</h1>
        <div>publisher: {{ source.publisher }}</div>
        <div>type: {{ source.type }}</div>
        <div>country: {{ source.countryName }}</div>
        <div>country code: {{ source.countryCode }}</div>
        <div>language: {{ source.langName }}</div>
        <div>language code: {{ source.langCode }}</div>
        <div>
          link: <a :href="source.url" target="_blank">{{ source.url }}</a>
        </div>
        <div>parser: {{ source.parser }}</div>
        <div class="mt-4 mb-2">comments</div>
        <div v-html="source.comments"></div>
        <div class="mt-4 mb-2">add comments</div>
        <b-form-textarea v-model="commentNew" placeholder="comments..." :rows="5" class="mb-2"></b-form-textarea>
        <b-btn class="mr-2" variant="outline-primary" size="sm" @click="commentsAdd">add comments</b-btn>
        <div class="my-4">
          <b-btn class="mr-2" variant="outline-primary" @click="commentsClear">clear comments</b-btn>
          <b-btn class="mr-2" variant="outline-primary" @click="showLogs">show logs</b-btn>
          <b-btn class="mr-2" variant="outline-primary" @click="testFeed">test feed</b-btn>
          <b-button class="mr-2" v-if="permissions.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: source.id, model: 'source' }}">Edit</b-button>
          <b-button v-if="permissions.delete"  v-b-toggle.collapse-1 variant="outline-danger">delete</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
                <b-btn variant="outline-danger" @click="deleteSource">confirm delete</b-btn>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
      <b-col>
        <h3>Health monitornig</h3>
        <div>publication: {{ moment(source.lastPublication).format('YYYY-MM-DD') }}</div>
        <div>success: {{ moment(source.lastSuccess).format('YYYY-MM-DD') }}</div>
        <div>error: {{ moment(source.lastError).format('YYYY-MM-DD') }}</div>
        <div>age: {{ source.age }}</div>
        <div>age alert: {{ source.ageAlert }}</div>
        <property-boolean-simple-vue :id="source.id" field="broken" name-display="broken" model="source" :value="source.broken"></property-boolean-simple-vue>
        <property-date :id-prop="source.id" field-prop="ok" name-display-prop="last reviewed OK" model-prop="source" :value-prop="source.ok"></property-date>
        <property-number :id-prop="source.id" field-prop="frequency" name-display-prop="frequency" model-prop="source" :value-prop="source.frequency"></property-number>
        <h3 class="mt-3">Technical fields</h3>
        <div>baselined: {{ source.baselined }}</div>
        <div>active: {{ source.active }}</div>
        <div>autotag: {{ source.autotag }}</div>
      </b-col>
    </b-row>
    <b-row v-if="show.logs">
      <b-col>
        <b-card class="mb-3" v-for="log in logs" :key="'logid-' + log.id">
          <div v-if="log.error" class="text-white bg-danger"><strong>ERROR</strong></div>
          <div v-else ><strong>success</strong></div>
          <div>{{log.message}}</div>
          <div>{{log.created_at}}</div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="show.news">
      <b-col>
        <div v-if="news.length==0">
          <b-alert show variant="danger">Error - feed is not working</b-alert>
        </div>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <h4 class="card-title">
            <a :href="item.link" target="_blank">{{item.name}}</a>
            <b-btn v-if="permissions.save" class="mr-2" variant="outline-primary" @click="save(item)">save</b-btn>
          </h4>
          <div class="mb-2">{{item.source}} - {{item.dateTime}}</div>
          <div class="mb-2">{{item.body}}</div>
          <div class="mb-2">{{item.publisher}} - {{item.type}}</div>
          <div class="mb-2">{{item.year}} - {{item.quarter}} - {{item.month}} - {{item.week}} </div>
          <div class="mb-2">{{item.link}}</div>
          <div class="mb-2">{{item.dateTime}}</div>
          <div class="mb-2">{{item.countryName}} - {{item.countryCode}} - {{item.langName}} - {{item.langCode}}</div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div>
        <b-form>
        <b-form-group horizontal class="mt-4">
          <b-input-group>
            <b-form-input v-model="q" placeholder="Check if external link exists in database..." />
              <b-btn :disabled="!linkValid" @click="search">search</b-btn>
          </b-input-group>
        </b-form-group>
        </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="newsSearch.length > 0">
      <b-col>
        <b-alert show variant="success"><h3>The link exists:</h3></b-alert>
        <div v-for="item in newsSearch" :key="item.id">
              <div class="mb-2 pb-2 border-bottom">
              <publication
                :propItem="item"
                :key="item.id"
                :tagsProp=tags
                :username="user.username"
                :permissionAddArticle="permissionAddArticle"
                :permissionAddRisk="permissionAddRisk"
                :permissionAddTag="permissionAddTag"
                :permissionArticlesWip="permissionArticlesWip"
                :permissionDeletePublication="permissionDeletePublication"
                :permissionInsights="permissionInsights"
                :permissionReadTagsPanel="permissionReadTagsPanel"
               />
              </div>
            </div>
      </b-col>
    </b-row>
    <b-row v-if="newsSearch.length === 0 && linkValid && showNewsSearch">
      <b-col>
        <b-alert show variant="danger">
          <h3>The link does not exist in our database</h3>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="loadingNews">
      <b-col>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        loading news for source...
      </b-col>
    </b-row>
    <b-row v-if="!loadingNews">
      <b-col>
        <h3>From publisher</h3>
        <div v-for="item in newsPublisher" :key="item.id">
              <div class="mb-2 pb-2 border-bottom">
              <publication
                :propItem="item"
                :key="item.id"
                :tagsProp=tags
                :username="user.username"
                :permissionAddArticle="permissionAddArticle"
                :permissionAddRisk="permissionAddRisk"
                :permissionAddTag="permissionAddTag"
                :permissionArticlesWip="permissionArticlesWip"
                :permissionDeletePublication="permissionDeletePublication"
                :permissionInsights="permissionInsights"
                :permissionReadTagsPanel="permissionReadTagsPanel"
               />
              </div>
            </div>
      </b-col>
      <b-col>
        <h3>From this specific feed</h3>
        <div v-for="item in news" :key="item.id">
              <div class="mb-2 pb-2 border-bottom">
              <publication
                :propItem="item"
                :key="item.id"
                :tagsProp=tags
                :username="user.username"
                :permissionAddArticle="permissionAddArticle"
                :permissionAddRisk="permissionAddRisk"
                :permissionAddTag="permissionAddTag"
                :permissionArticlesWip="permissionArticlesWip"
                :permissionDeletePublication="permissionDeletePublication"
                :permissionInsights="permissionInsights"
                :permissionReadTagsPanel="permissionReadTagsPanel"
               />
              </div>
            </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import PropertyBooleanSimpleVue from '@/components/sql/PropertyBooleanSimple.vue'
import PropertyDate from '@/components/sql/PropertyDate.vue'
import PropertyNumber from '@/components/sql/PropertyNumber.vue'
import Publication from '@/components/PublicationNew'

export default {
  props: ['permissionDelete', 'permissionSave'],
  components: {
    PropertyBooleanSimpleVue,
    PropertyDate,
    PropertyNumber,
    Publication
  },
  computed: {
    linkValid () {
      return this.q.match(/^(http|https):\/\//)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Source"
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.$stat.log({ page: 'source', action: 'open source', model: 'source', model_id: this.$route.params.id })
    this.permissions.delete = ac.can(this.user.acgroups).deleteAny('source').granted
    this.permissions.edit = ac.can(this.user.acgroups).updateAny('source').granted
    this.permissions.save = ac.can(this.user.acgroups).createAny('publication').granted
    this.permissionEditTopic = ac.can(this.user.acgroups).createAny('topicUpdate').granted
    this.permissionReadTopicUpdate = ac.can(this.user.acgroups).readAny('topicUpdate').granted
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.load()
  },
  data () {
    return {
      commentNew: '',
      feedTestDate: null,
      loading: true,
      loadingNews: true,
      logs: [],
      message: '',
      moment: moment,
      news: [],
      newsPublisher: [],
      newsSearch: [],
      permissions: {
        delete: false,
        edit: false,
        save: false
      },
      permissionReadTopicUpdate: false,
      permissionEditTopic: false,
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadTagsPanel: false,
      permissionInsights: true,
      show: {
        logs: false,
        news: false
      },
      showNewsSearch: false,
      q: '',
      source: {},
      tags: []
    }
  },
  methods: {
    clearShow: function () {
      _.forIn(this.show, (value, key) => {
        this.show[key] = false
      })
    },
    commentsAdd: async function () {
      this.loading = true
      if (!this.source.comments) {
        this.source.comments = ''
      }
      const timenow = moment().format('YYYY-MM-DD HH:mm:ss')
      const comments = `${this.source.comments} <p><strong>${this.user.username} on ${timenow}:</strong></p><p>${this.commentNew}</p>`
      try {
        let params = {
          body: { comments: comments }
        }
        await this.$Amplify.API.put('cosmos', `/standard/source/${this.source.id}`, params)
        this.commentNew = ''
      } catch (e) {
        this.$bvModal.msgBoxOk('Saving failed!')
        this.$logger.warn('event saving error: ' + e)
      }
      this.load()
    },
    commentsClear: async function () {
      this.loading = true
      try {
        let params = {
          body: { comments: '' }
        }
        await this.$Amplify.API.put('cosmos', `/standard/source/${this.source.id}`, params)
        this.commentNew = ''
      } catch (e) {
        this.$bvModal.msgBoxOk('Saving failed!')
        this.$logger.warn('event saving error: ' + e)
      }
      this.load()
    },
    deleteSource: async function () {
      this.$logger.debug('started delete')
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}`
        let response = await this.$Amplify.API.del(apiName, path)
        this.$logger.debug('response', response)
        this.loading = true
        this.clearShow()
        this.show.table = true
        this.getSources()
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    load: async function () {
      this.$logger.debug('getSources started')
      this.loading = true
      this.loadingNews = true
      let source
      try {
        [source] = await this.$Amplify.API.get('cosmos', `/source/${this.$route.params.id}/adminview`)
        if (source.frequency === 0) {
          source.frequency = 180
        }
        const maxdate = moment(source.maxdate)
        const today = moment()
        source.age = today.diff(maxdate, 'days')
        const ageAlert = source.age - source.frequency
        if (ageAlert < 0) {
          source.ageAlert = 'ok'
        } else {
          source.ageAlert = ageAlert
        }
        this.source = source
        this.loading = false
        this.tags = await this.$Amplify.API.get('cosmos', '/tags')
        this.news = await this.$Amplify.API.get('cosmos', `/news/source/${this.source.source}/limit/30`)
        this.newsPublisher = await this.$Amplify.API.get('cosmos', `/news/regulator/${this.source.publisher}/limit/30`)
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    save: async function (item) {
      try {
        let apiName = 'cosmos'
        let path = `/news/publication`
        let params = { body: item }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.$bvModal.msgBoxOk('News item saved!')
      } catch (e) {
        this.$bvModal.msgBoxOk('Saving failed!')
        this.$logger.warn('event saving error: ' + e)
      }
    },
    search: async function () {
      this.showNewsSearch = false
      try {
        this.newsSearch = await this.$Amplify.API.post('cosmos', `/search/link`, { body: { link: this.q } })
        this.showNewsSearch = true
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    showLogs: async function (source, index, event) {
      try {
        let apiName = 'cosmos'
        let path = `/source/${this.source.id}/scrapelog`
        let response = await this.$Amplify.API.get(apiName, path)
        this.logs = response
        this.clearShow()
        this.show.logs = true
        console.log(this.logs)
      } catch (e) {
        this.$logger.warn('getting sources error' + e)
      }
    },
    testFeed: async function () {
      this.$logger.debug('testFeed started')
      try {
        let apiName = 'cosmos'
        let path = '/rss/test'
        let params = { body: { source: this.source } }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.news = response
        this.clearShow()
        this.show.news = true
      } catch (e) {
        this.$logger.warn('testRSS error' + e)
      }
    }
  }
}
</script>

<style></style>
