<template>
  <b-form inline>
    <span v-if="!saving">
      <b-form-checkbox
        v-model="valueMain"
        required
        :value="true"
        :unchecked-value="false"
        class="mb-2">
        {{nameDisplay}}
      </b-form-checkbox>
    </span>
    <span v-if="saving">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </span>
  </b-form>
</template>

<script>
export default {
  name: 'PropertyBoolean',
  props: ['id', 'field', 'nameDisplay', 'model', 'value'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      saving: false,
      valueMain: this.value
    }
  },
  watch: {
    valueMain: async function () {
      this.$logger.debug(`saving start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        const body = {}
        body[this.field] = this.valueMain
        const params = {
          body: body
        }
        const response = await this.$Amplify.API.put('cosmos', `/standard/${this.model}/${this.id}`, params)
        this.$logger.debug('saved OK:', response)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
